import React from "react";
import { Link } from "gatsby";

const Faq = () => {
  return (
    <div className="pb-13 pb-md-18 pb-lg-25">
      <div className="container">
        {/* Section Title */}
        <div className="row justify-content-center">
          <div className="col-xl-8 col-lg-9">
            <div className="text-center mb-13 mb-lg-19">
              <h2 className="font-size-9 mb-0">Frequently Asked Question</h2>
            </div>
          </div>
        </div>
        {/* End Section Title */}
        {/* Faqs */}
        {/* Faqs */}
        <div className="row justify-content-center">
          {/* Single Faq */}
          <div className="col-lg-6 col-md-10">
            <div
              className="d-flex mb-10 mb-lg-17"
              data-aos="fade-up"
              data-aos-duration={300}
            >
              <div className="mr-6">
                <div className="bg-question-icon-green circle-28 text-white mt-1">
                  <i className="fa fa-question" />
                </div>
              </div>
              <div className="">
                <h4 className="font-size-7 mb-7">
                  How does payment work if I subscribe?
                </h4>
                <p className="font-size-5 pr-md-10 pr-lg-0 mb-0">
                  When you join the app, you benefit from a free vault for 3
                  months. This means you can take the time to explore
                  stress-free and without having to provide payment information.
                  Once you want to activate the premium plan, you just need
                  to upgrade your account and that's when you will be asked for
                  your payment information.
                </p>
              </div>
            </div>
          </div>
          {/* End Single Faq */}
          {/* Single Faq */}
          <div className="col-lg-6 col-md-10">
            <div
              className="d-flex mb-10 mb-lg-17"
              data-aos="fade-up"
              data-aos-duration={600}
            >
              <div className="mr-6">
                <div className="bg-question-icon-green  circle-28 text-white mt-1">
                  <i className="fa fa-question" />
                </div>
              </div>
              <div className="">
                <h4 className="font-size-7 mb-7">
                  What are the next features to be added?
                </h4>
                <p className="font-size-5 pr-md-10 pr-lg-0 mb-0">
                  We are currently working on building our public roadmap for
                  next feature to add based on you guys feedback! Please feel
                  free to share with us any ideas and functionalities you would
                  like to see.
                </p>
              </div>
            </div>
          </div>
          {/* End Single Faq */}
          {/* Single Faq */}
          <div className="col-lg-6 col-md-10">
            <div
              className="d-flex mb-10 mb-lg-17"
              data-aos="fade-up"
              data-aos-duration={300}
            >
              <div className="mr-6">
                <div className="bg-question-icon-green circle-28 text-white mt-1">
                  <i className="fa fa-question" />
                </div>
              </div>
              <div className="">
                <h4 className="font-size-7 mb-7">
                  How to contact with your support?
                </h4>
                <p className="font-size-5 pr-md-10 pr-lg-0 mb-0">
                  We provide feedback forms in various places inside the app
                  where you can contact us. Feel free to reach out for any
                  questions, ideas or else. If you like to speak with someone
                  now, please checkout our contact info{" "}
                  <Link className="elephant-tradewind" to="/contact">here</Link>.
                </p>
              </div>
            </div>
          </div>
          {/* End Single Faq */}
          {/* Single Faq */}
          <div className="col-lg-6 col-md-10">
            <div
              className="d-flex mb-10 mb-lg-17"
              data-aos="fade-up"
              data-aos-duration={600}
            >
              <div className="mr-6">
                <div className="bg-question-icon-green circle-28 text-white mt-1">
                  <i className="fa fa-question" />
                </div>
              </div>
              <div className="">
                <h4 className="font-size-7 mb-7">
                  What is the level of data security?
                </h4>
                <p className="font-size-5 pr-md-10 pr-lg-0 mb-0">
                  We take the security of your data very seriously. All
                  necessary measures are in place to go beyond expectations and
                  requirements in the legal area. Click{" "}
                  <Link className="elephant-tradewind" to="/404">here</Link> to know more about our Privacy
                  Policy.
                </p>
              </div>
            </div>
          </div>
          {/* End Single Faq */}
        </div>
        {/* Button  */}
        <div
          className="text-center pt-lg-3 pb-lg-3"
          data-aos="fade-up"
          data-aos-duration={500}
        >
          <p className="font-size-6 mb-0">
            Still have a question about Lawy?
            <a
              className="text-blue-3 btn-link-with-underline ml-5"
              href="/contact"
            >
              Contact with us
            </a>
          </p>
        </div>
        {/* Button End */}
      </div>
    </div>
  );
};

export default Faq;
