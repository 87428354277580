import React from "react";
import Hero from "../sections/pricing/Hero";
import Pricing from "../sections/pricing/Pricing";
import Faq from "../sections/pricing/Faq";

const PricingPage = () => {
  return (
    <>
      <Hero />
      <Pricing />
      <Faq />
    </>
  );
};
export default PricingPage;
